import { Pipe, PipeTransform } from '@angular/core';
import { WindowsSizeService } from '@app/services/windows-size/windows-size.service';
import { environment } from '@environments/environment';
import { ImagePipe } from '../image/image.pipe';

@Pipe({
    name: 'autocomplete',
    standalone: true,
})
export class AutocompletePipe implements PipeTransform {
    constructor(private windowsSizeService: WindowsSizeService) {}

    transform(value: unknown, ...args: unknown[]): string {
        const imagePipe: ImagePipe = new ImagePipe(this.windowsSizeService);

        const data: any = value;
        if (!data) return '';

        switch (args[0]) {
            case 'image':
                {
                    if (!data.picture || data.picture === '') {
                        return 'assets/img/noimage.svg';
                    }

                    if (data.type === 'category' || data.type === 'subcategory')
                        return environment.CATEGORY_FILE + data.picture;
                    else if (
                        data.type === 'profile' ||
                        data.type === 'publication'
                    ) {
                        if (data.picture) return 'assets/img/no-image.svg';

                        const picture: string = data.picture;

                        return picture.includes('https')
                            ? picture
                            : environment.URL_FILE + picture;
                    }
                }
                break;

            case 'name':
                return data.name;
                break;

            case 'type':
                {
                    const messages: { [key: string]: string } = {
                        category: 'Ir a categoría',
                        subcategory: 'Ir a categoría',
                        profile: 'Ir al perfil',
                        publication: 'Ir a publicación',
                    };

                    return messages[data.type];
                }
                break;
        }

        return '';
    }
}
