<div class="search-box">
    <img src="assets/img/icons/tab-3.svg" alt="search icon" />
    <input
        id="autoCompleted"
        #inputSearch
        [ngClass]="{
            'rounded': rounded,
            'centered': centered,
            'input-without-notification': !notification,
        }"
        [formControl]="controlSearch"
        (keydown.enter)="onKeyDown()"
        type="search"
        [placeholder]="placeholder" />

    @if (notification) {
        <button [routerLink]="['/notificaciones/mis_notificaciones']" >
            <label class="no-mobile">Crear notificación</label>
            <img src="assets/img/bell-white.svg" />
            <label class="mobile">+</label>
        </button>
    }
    @if (autoCompleted.length !== 0) {
        <app-auto-completed-search
            [color]="color"
            [backgroundColor]="backgroundColor"
            [dataSourceFilter]="autoCompleted"
            (result)="selectAutoCompleted($event)">
        </app-auto-completed-search>
    }
</div>
