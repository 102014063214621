import { Platform } from '@angular/cdk/platform';
import { NgClass, NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
    isCategoriesOrSubcategories,
    parseDataAlgolia,
} from '@app/functions/data-algolia-search.function';
import { CategoryParseView } from '@app/models/category/category-parse-view.model';
import { ResponseSearchInput } from '@app/models/category/responseSearchInput.model';
import { SearchPipe } from '@app/pipes/search/search.pipe';
import { AlgoliaService } from '@app/providers/algolia/algolia.service';
import { WindowsSizeService } from '@app/services/windows-size/windows-size.service';
import { debounceTime, Subscription } from 'rxjs';
import { AutoCompletedSearchComponent } from '../auto-completed-search/auto-completed-search.component';
import { Router, RouterLink } from '@angular/router';

@Component({
    selector: 'app-input-search',
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        AutoCompletedSearchComponent,
        NgClass,
        RouterLink,
    ],
    templateUrl: './input-search.component.html',
    styleUrl: './input-search.component.sass',
})
export class InputSearchComponent implements OnInit, OnDestroy {
    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent): void {
        const targetElement = event.target as HTMLElement;
        const elementId = targetElement.id;

        if (elementId !== 'autoCompleted') {
            this.autoCompleted = [];
        }
    }

    @Input() notification: boolean = false;
    @Input() color: string = '#FFFFFF';
    @Input() backgroundColor: string = '';
    @Input() placeholder: string = '¿Qué quieres buscar?';
    @Input() centered: boolean = false;
    @Input() rounded: boolean = false;
    @Output() viewSearch = new EventEmitter<ResponseSearchInput>();
    @ViewChild('inputSearch') inputSearch!: ElementRef;

    autoCompleted: CategoryParseView[] = [];

    controlSearch: FormControl = new FormControl('');

    listSubscription: Subscription[];

    searchPipe: SearchPipe;

    private readonly _platform = inject(Platform);
    private readonly algoliaService = inject(AlgoliaService);
    public readonly windowsSizeService = inject(WindowsSizeService);
    public readonly router = inject(Router);

    constructor() {
        this.searchPipe = new SearchPipe(this.windowsSizeService);
        this.listSubscription = [new Subscription()];
    }

    ngOnInit(): void {
        this.subscriptionAutoComplete();
    }

    ngOnDestroy() {
        this.listSubscription.forEach(itrSub => {
            itrSub.unsubscribe();
        });
    }

    private subscriptionAutoComplete(): void {
        if (!this._platform.isBrowser) return;

        this.listSubscription[0] = this.controlSearch.valueChanges
            .pipe(debounceTime(500))
            .subscribe((res: string) => {
                const word: string = res.trim().toLowerCase();

                if (word == '') {
                    this.autoCompleted = [];
                    return;
                }
                this.algoliaService.indexCategory
                    .search(word, {
                        exactOnSingleWordQuery: 'attribute',
                    })
                    .then(({ hits }: any) => {
                        const listAutoComplete: CategoryParseView[] =
                            isCategoriesOrSubcategories(hits, this.searchPipe);

                        const order = [
                            'category',
                            'subcategory',
                            'publication',
                            'profile',
                        ];
                        this.autoCompleted.unshift(
                            ...listAutoComplete
                                .sort((a, b) => {
                                    return (
                                        order.indexOf(a.type) -
                                        order.indexOf(b.type)
                                    );
                                })
                                .slice(0, 5)
                        );
                    })
                    .catch(() => {});

                this.algoliaService.index
                    .search(word, {
                        exactOnSingleWordQuery: 'attribute',
                    })
                    .then(({ hits }: any) => {
                        const listAutoComplete: CategoryParseView[] =
                            parseDataAlgolia(hits, word, this.searchPipe, true);

                        const order = [
                            'category',
                            'subcategory',
                            'publication',
                            'profile',
                        ];
                        this.autoCompleted.push(
                            ...listAutoComplete.sort((a, b) => {
                                return (
                                    order.indexOf(a.type) -
                                    order.indexOf(b.type)
                                );
                            })
                        );
                    })
                    .catch(() => {});

                return;
            });
    }

    search(): void {
        const word: string = this.controlSearch.value;
        this.viewSearch.emit({ word: word.trim(), list: this.autoCompleted });

        this.autoCompleted = [];
    }

    selectAutoCompleted(res: CategoryParseView): void {
        const word: string = this.controlSearch.value;
        this.autoCompleted = [];

        this.controlSearch.setValue(res.name, { emitEvent: false });
        this.viewSearch.emit({ word: word.trim(), list: [res] });
    }

    onKeyDown() {
        const word: string = this.controlSearch.value;
        this.autoCompleted = [];
        this.inputSearch.nativeElement.blur();
        this.router.navigate(['/resultados/', word]);
    }
}
