import { CategoryParseView } from '@app/models/category/category-parse-view.model';
import {
    AlgoliaResponse,
    AlgoliaResponseCategory,
    Category,
    Subcategory,
    UserId,
} from '@app/models/category/category_algolia.model';
import { SearchPipe } from '@app/pipes/search/search.pipe';
import { TitleCasePipe } from '@angular/common';

export function ParseDataCategory(
    res: any[],
    searchPipe: SearchPipe
): CategoryParseView[] {
    const list: CategoryParseView[] = [];
    res.forEach((itrData: any) => {
        list.push({
            _id: itrData._id,
            name: itrData.name,
            type: 'category',
            picture: searchPipe.transform(itrData.pictures, 'image-category'),
        });
    });

    return list;
}

export function ParseDataSubCategory(
    res: any[],
    searchPipe: SearchPipe
): CategoryParseView[] {
    const list: CategoryParseView[] = [];

    res.forEach((itrData: any) => {
        const picture: string = itrData.pictures
            ? searchPipe.transform(itrData.pictures, 'image-category')
            : '';

        list.push({
            _id: itrData._id,
            name: searchPipe.transform(itrData.name, 'name-category'),
            type: 'subcategory',
            picture,
        });
    });

    return list;
}

export function ParseDataPublication(
    res: any[],
    word: string,
    searchPipe: SearchPipe,
    inTitle: boolean = true
): any[] {
    const list: any[] = [];

    res.forEach((itrData: any) => {
        if (inTitle && itrData.title.includes(word)) {
            list.push({
                user: { ...itrData.userId, location: itrData.location },
                _id: itrData._id,
                name: searchPipe.transform(itrData.title, 'name-category'),
                type: 'publication',
                picture:
                    itrData.picture.length === 0
                        ? ''
                        : searchPipe.transform(
                              itrData.picture[0],
                              'image-profile-publication'
                          ),
            });
        }
        if (!inTitle) {
            list.push({
                user: { ...itrData.userId, location: itrData.location },
                _id: itrData._id,
                name: searchPipe.transform(itrData.title, 'name-category'),
                type: 'publication',
                picture:
                    itrData.picture.length === 0
                        ? ''
                        : searchPipe.transform(
                              itrData.picture[0],
                              'image-profile-publication'
                          ),
            });
        }
    });

    return list;
}

export function FilterDataAlgoliaSearch(
    res: CategoryParseView[]
): CategoryParseView[] {
    const listCategory: CategoryParseView[] = [];
    const listSubCategory: CategoryParseView[] = [];
    const listPublication: CategoryParseView[] = [];
    const listProfile: CategoryParseView[] = [];

    res.forEach((itrData: CategoryParseView) => {
        if (itrData.type === 'publication') listPublication.push(itrData);
        else if (itrData.type === 'subcategory') listSubCategory.push(itrData);
        else if (itrData.type === 'category') listCategory.push(itrData);
        else if (itrData.type === 'profile') listProfile.push(itrData);
    });

    if (listPublication.length !== 0) return listPublication;
    else if (listSubCategory.length !== 0) return listSubCategory;
    else if (listCategory.length !== 0) return listCategory;
    else if (listProfile.length !== 0) return listProfile;

    return [];
}

export function parseDataAlgolia(
    res: AlgoliaResponse[],
    word: string,
    searchPipe: SearchPipe,
    ignoreCategories?: boolean
): CategoryParseView[] {
    const listMatch: CategoryParseView[] = [];

    res.forEach((itrData: AlgoliaResponse) => {
        const categoryMatches = isCategoryOrSubcategory(
            itrData.categories,
            word,
            listMatch,
            searchPipe
        );
        if (!ignoreCategories) {
            listMatch.push(...categoryMatches);
        }

        const profile = isProfile(itrData.userId, word, listMatch, searchPipe);

        if (profile) listMatch.push(profile);

        const publication = isPublication(itrData, word, searchPipe);
        if (publication) listMatch.push(publication);
    });

    return listMatch;
}

export function isCategoryOrSubcategory(
    categories: Category[],
    word: string,
    listMatch: CategoryParseView[],
    searchPipe: SearchPipe
): CategoryParseView[] {
    return categories.reduce(
        (matches: CategoryParseView[], category: Category) => {
            if (category.mainCategory.includes(word)) {
                const categoryId: string = isString(category.mainCategoryId)
                    ? category.mainCategoryId
                    : category.mainCategoryId?._id;

                const existsInList = listMatch.some(
                    match =>
                        match.type === 'category' &&
                        match._id.includes(categoryId)
                );

                if (!existsInList) {
                    const picture: string = isString(category.mainCategoryId)
                        ? ''
                        : searchPipe.transform(
                              category.mainCategoryId?.pictures,
                              'image-category'
                          );

                    matches.push({
                        _id: categoryId,
                        name: searchPipe.transform(
                            category.mainCategory,
                            'name-category'
                        ),
                        type: 'category',
                        picture,
                    });
                }
            }

            category.subcategories.forEach((subcategory: Subcategory) => {
                if (!subcategory.nameSubcategory.includes(word)) return;

                const subCategoryId: string = isString(
                    subcategory.subcategoryId
                )
                    ? subcategory.subcategoryId
                    : subcategory.subcategoryId?._id;
                const categoryId: string = isString(category.mainCategoryId)
                    ? category.mainCategoryId
                    : category.mainCategoryId?._id;

                const existsInList = listMatch.some(
                    match =>
                        match.type === 'subcategory' &&
                        match._id.includes(subCategoryId)
                );

                if (!existsInList) {
                    const picture: string = isString(subcategory.subcategoryId)
                        ? ''
                        : searchPipe.transform(
                              subcategory.subcategoryId?.pictures,
                              'image-category'
                          );

                    matches.push({
                        _id: `${categoryId}~${subCategoryId}`,
                        name: searchPipe.transform(
                            subcategory.nameSubcategory,
                            'name-category'
                        ),
                        type: 'subcategory',
                        picture,
                    });
                }
            });

            return matches;
        },
        []
    );
}

export function isCategoriesOrSubcategories(
    resp: AlgoliaResponseCategory[],
    searchPipe: SearchPipe
): CategoryParseView[] {
    const listMatch: CategoryParseView[] = [];

    if (resp.length === 0) {
        return listMatch;
    }

    resp.forEach(category => {
        const type = category.isCategory ? 'category' : 'subcategory';
        const parent = category.parent ?? null;
        listMatch.push({
            _id: category._id,
            name: category.name,
            picture: category?.pictures
                ? searchPipe.transform(category?.pictures, 'image-category')
                : '',
            type,
            parent,
        });
    });

    return listMatch;
}

export function isProfile(
    userId: UserId,
    word: string,
    listMatch: CategoryParseView[],
    searchPipe: SearchPipe
): CategoryParseView | null {
    const titleCasePipe: TitleCasePipe = new TitleCasePipe();
    const fullName = titleCasePipe.transform(
        `${userId.info.firstName} ${userId.info.lastName}`
    );

    if (fullName.toLocaleLowerCase().includes(word)) {
        const existsInList = listMatch.some(
            match => match.type === 'profile' && match._id === userId._id
        );

        if (!existsInList) {
            return {
                _id: userId._id,
                name: fullName,
                type: 'profile',
                picture: searchPipe.transform(
                    userId.info.picture,
                    'image-profile-publication'
                ),
            };
        }
    }

    return null;
}

export function isPublication(
    publication: AlgoliaResponse,
    word: string,
    searchPipe: SearchPipe
): CategoryParseView | null {
    if (publication.title.includes(word)) {
        return {
            _id: publication._id,
            name: searchPipe.transform(publication.title, 'name-category'),
            type: 'publication',
            picture:
                publication.picture.length === 0
                    ? ''
                    : searchPipe.transform(
                          publication.picture[0],
                          'image-profile-publication'
                      ),
        };
    }
    return null;
}

export function isString(value: any): value is string {
    return typeof value === 'string';
}
