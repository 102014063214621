<div id="autoCompleted" class="container_autoComp">
    @for (itrData of dataSource; track $index) {
        <div
            id="autoCompleted"
            class="text with_image"
            (click)="selectItem(itrData)">
            <img
                [alt]="itrData._id"
                [src]="itrData.picture"
                onerror="this.src='assets/img/noimage.svg'" />
            <p>{{ autocompletePipe.transform(itrData, 'name') }}</p>
            <button
                (click)="navigatePage(itrData)"
                [style.background-color]="backgroundColor"
                [style.color]="color">
                {{ autocompletePipe.transform(itrData, 'type') }}
            </button>
        </div>
    }
</div>
